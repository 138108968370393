import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';
import WinnerA from '../../assets/images/highlights/MANAV-SHARMA-WINNER-CATEGORY-A.jpg';
import RUNNERupA from '../../assets/images/highlights/DHAIRYA-THAMMAN-RUNNER-UP-CATEGORY-A.jpg';
import WinnerB from '../../assets/images/highlights/CHANDIGARH-UNIVERSITY-WINNER-CATEGORY-B.jpg';
import RUNNERupB from '../../assets/images/highlights/FOLK-BEAT-INTERNATIONAL-RUNNER-UP-CATEGORY-B.jpg';

function Winners2024() {
    useEffect(() => {
        document.title = 'Winners of 2024 - Bhangra Premier League - Red FM';
    }, []);
    return (
        <>
            <HeaderSubPages pageName='Winners of 2024' />
            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-10'>
                            <div className='subpage-main-box'>
                                <div className='row g-4 mt-2'>
                                    <div className='col-12'>
                                        <h2 className='text-dark-red mb-0 h5'>Category A - Below 6 Participants</h2>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='img-box'>
                                            <img src={WinnerA} className='w-100' alt='winner of 2024' />
                                            <h3 className='text-yellow h6 mt-3'>Winner - Manav Sharma</h3>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='img-box'>
                                            <img src={RUNNERupA} className='w-100' alt='winner of 2024' />
                                            <h3 className='text-yellow h6 mt-3'>Runner Up - Dhairya Thamman</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* winner category A end*/}
                                <div className='row g-4 mt-2'>
                                    <div className='col-12'>
                                        <h2 className='text-dark-red mb-0 h5'>Category B - Above 6 Participants</h2>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='img-box'>
                                            <img src={WinnerB} className='w-100' alt='winner of 2024' />
                                            <h3 className='text-yellow h6 mt-3'>Winner - Chandigarh University</h3>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='img-box'>
                                            <img src={RUNNERupB} className='w-100' alt='winner of 2024' />
                                            <h3 className='text-yellow h6 mt-3'>Runner Up - DAV College Jalandhar</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* winner category A end*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Winners2024;
