import { Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import React from 'react';

const VideoPlayerModal = ({ show, handleClose, url }) => {
    return (
        <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} fullscreen>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <ReactPlayer url={url} width='100%' height='100%' controls playsinline playing loading='lazy' />
            </Modal.Body>
        </Modal>
    );
};

export default VideoPlayerModal;
