import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';
import { useUserContext } from '../../context/user-context';
import '../../assets/css/my-team-style.css';

const MyTeam = () => {
    const { user } = useUserContext();

    useEffect(() => {
        document.title = 'My Team - Bhangra Premier League - Red FM';
    }, []);

    return (
        <>
            <HeaderSubPages pageName='My Team' />

            <section className='mt-3'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8'>
                            <div className='team-main-box'>
                                {user?.group_name.split(',').map((item, i) => {
                                    return (
                                        <div className='team-single-box' key={i}>
                                            <div className='team-img-rounded'></div>
                                            <div className='content-right'>
                                                <h3 className='team-name'>{item}</h3>
                                                <h4 className='team-position'>{i === 0 ? 'Captain' : 'Partner'}</h4>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MyTeam;
