import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';

import HeaderSubPages from '../../components/header/header-sub-pages';
import ShareModel from '../../components/entries/share-model';
import { useUserContext } from '../../context/user-context';
import { useAuthContext } from '../../context/auth.context';

import PlayIcon from '../../assets/icons/video-icon.png';
import CopyIcon from '../../assets/icons/copy.svg';

import service, { methods } from '../../service';

import '../../assets/css/entries-style.css';

const PER_PAGE = 5;

const Entries = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [activePlayerId, setActivePlayerId] = useState(Number(searchParams.get('participant_id')));
    const [search, setSearch] = useState(searchParams.get('search') || '');
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    const [videoLengthZero, setVideoLengthZero] = useState(false);
    const [isVideoDetails, setIsVideoDetails] = useState(false);
    const [currentVideo, setCurrentVideo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [videoList, setVideoList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [isPlay, setIsPlay] = useState(false);
    const [page, setPage] = useState(1);

    const { handleOpenVote } = useAuthContext();
    const { user, userType } = useUserContext();

    const debounceTimeoutRef = useRef(null);

    const shareUrl = window.location.href;

    const fetchEntries = async (search, page) => {
        setIsLoading(true);
        try {
            const res = await service({
                url: 'entries/list/',
                method: methods.GET,
                params: { page: page, item_per_page: PER_PAGE, searchkeyword: search },
            });

            if (res.totalCount <= page * PER_PAGE) {
                setHasMore(false);
            }

            if (!activePlayerId) {
                const newSearchParams = {
                    ...(res.payload[0]?.participant_id && { participant_id: res.payload[0]?.participant_id }),
                    ...(search && { search }),
                };
                setActivePlayerId(res.payload[0]?.participant_id);
                setSearchParams(newSearchParams);
            }

            if (res.payload.length === 0 && res.totalCount === 0) {
                setVideoLengthZero(true);
            }

            setVideoList((prev) => [...prev, ...res.payload]);
        } catch (error) {
            setVideoLengthZero(true);
            setVideoList([]);
            // toast.error(error);
            console.error(`Error :: fetchEntries :: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCurrentVideoDetails = async (participant_id) => {
        try {
            const res = await service({
                url: `entries/details/${participant_id}/`,
                method: methods.GET,
            });
            if (res.payload.length === 0) {
                setVideoLengthZero(true);
                setCurrentVideo({});
                setHasMore(false);
                setVideoList([]);
            } else {
                setCurrentVideo(res.payload[0]);
            }
        } catch (error) {
            setIsVideoDetails(true);
            // toast.error(error);
            console.error(`Error :: fetchCurrentVideoDetails :: ${error}`);
        }
    };

    const handleVote = async () => {
        if (!user || !userType) {
            handleOpenVote();
        } else {
            const payload = {
                participant_id: currentVideo?.participant_id,
            };
            try {
                await service({
                    url: 'vote/',
                    method: methods.POST,
                    data: payload,
                });

                setCurrentVideo((prev) => ({
                    ...prev,
                    alreadyVoted: true,
                }));
                const tempVideoList = [...videoList];
                const index = tempVideoList.findIndex((item) => item?.participant_id === currentVideo?.participant_id);
                tempVideoList[index].alreadyVoted = true;
                setVideoList(tempVideoList);
            } catch (error) {
                toast.error(error);
                console.error(`Error :: handleVote :: ${error}`);
            }
        }
    };

    const handleChange = (e) => {
        setPage(1);
        setSearchParams({ search: e.target.value });
        setSearch(e.target.value);
        setHasMore(true);
        setVideoList([]);
        setActivePlayerId();
        setVideoLengthZero(false);
        setIsPlay(false);

        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
            setDebouncedSearch(e.target.value);
        }, 1000);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }

            debounceTimeoutRef.current = setTimeout(() => {
                const scrollY = window.scrollY || window.pageYOffset;
                const windowHeight = window.innerHeight;
                const documentHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);

                const threshold = 100;

                if (scrollY + windowHeight >= documentHeight - threshold && !isLoading && hasMore) {
                    setPage((prev) => prev + 1);
                }
            }, 200);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [user, userType, hasMore, isLoading]);

    useEffect(() => {
        fetchEntries(debouncedSearch, page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, debouncedSearch]);

    useEffect(() => {
        if (activePlayerId) {
            fetchCurrentVideoDetails(activePlayerId);
        }
    }, [activePlayerId]);

    useEffect(() => {
        document.title = 'Entries - Bhangra Premier League - Red FM';
    }, []);

    return (
        <>
            <HeaderSubPages pageName='Entries' showSearch search={search} handleChange={handleChange} />

            {videoLengthZero && !search && (
                <Container>
                    <div className='d-flex align-items-center justify-content-center mt-5'>
                        <h1 className='text-white text-center'>Watch out this space for update</h1>
                    </div>
                </Container>
            )}

            {((videoLengthZero && search) || (isVideoDetails && videoLengthZero === false)) && (
                <Container>
                    <div className='d-flex align-items-center justify-content-center mt-5'>
                        <h1 className='text-white text-center'>No Entry Found</h1>
                    </div>
                </Container>
            )}

            {videoList.length > 0 && videoLengthZero === false && (
                <Container className='pt-4 pb-5 h-100'>
                    <Row className='justify-content-center'>
                        <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                            <Row>
                                {isVideoDetails === false && (
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className='big-video-container'>
                                            <ReactPlayer
                                                url={currentVideo?.file_path}
                                                width='100%'
                                                height='100%'
                                                className='react-player'
                                                loading='lazy'
                                                playsinline
                                                playing={isPlay}
                                                controls
                                                config={{
                                                    vimeo: {
                                                        playerOptions: {
                                                            play_button_position: 'center',
                                                            playsinline: true,
                                                            transcript: false,
                                                            pip: false,
                                                            quality_selector: false,
                                                            cc: false,
                                                            speed: false,
                                                            colors: ['ffd117', 'FF0000', 'FF0000', '000000'],
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>

                                        <Row className='mt-3 mb-5 gy-3'>
                                            <Col xl={9} lg={9} md={9} sm={12} xs={12} className='text-white'>
                                                <h5>{currentVideo?.title}</h5>
                                                <p
                                                    className='text mb-0 fs-12'
                                                    dangerouslySetInnerHTML={{
                                                        __html: currentVideo?.description,
                                                    }}
                                                />
                                            </Col>
                                            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                                <div className='d-flex justify-content-lg-end gap-2'>
                                                    <WhatsappShareButton url={shareUrl}>
                                                        <WhatsappIcon size={40} round />
                                                    </WhatsappShareButton>
                                                    <FacebookShareButton url={shareUrl}>
                                                        <FacebookIcon size={40} round />
                                                    </FacebookShareButton>
                                                    <div
                                                        className='copy rounded-circle d-flex align-items-center justify-content-center cursor-pointer'
                                                        onClick={() => navigator.clipboard.writeText(shareUrl)}
                                                    >
                                                        <img src={CopyIcon} alt='Copy Icon' width={20} height={20} />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                                                <button
                                                    type='button'
                                                    className={`btn-theme btn-secondary w-100 py-1 ${
                                                        currentVideo?.alreadyVoted && 'disable-btn'
                                                    }`}
                                                    onClick={handleVote}
                                                    disabled={currentVideo?.alreadyVoted}
                                                >
                                                    <small>
                                                        {currentVideo?.alreadyVoted ? 'Already Voted' : 'Vote Now'}
                                                    </small>
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}

                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className='small-video-bg p-3'>
                                    <Row className='gy-2'>
                                        {videoList.map((item, i) => (
                                            <Col xl={6} lg={6} md={12} sm={12} xs={12} key={i}>
                                                <Row
                                                    className='text-white gx-3 mb-3 cursor-pointer'
                                                    onClick={() => {
                                                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                                        setCurrentVideo(item);
                                                        const newSearchParams = {
                                                            ...(item.participant_id && {
                                                                participant_id: item.participant_id,
                                                            }),
                                                            ...(search && { search }),
                                                        };
                                                        setSearchParams(newSearchParams);
                                                        setIsPlay(true);
                                                        setIsVideoDetails(false);
                                                    }}
                                                >
                                                    <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                                                        <div className='small-video-container'>
                                                            <ReactPlayer
                                                                url={item?.file_path}
                                                                light={true}
                                                                width='100%'
                                                                height='100%'
                                                                className='react-player'
                                                                loading='lazy'
                                                                playIcon={
                                                                    <img src={PlayIcon} alt='Play Icon' width='30px' />
                                                                }
                                                                playsinline
                                                                playing={false}
                                                                controls={false}
                                                                onContextMenu={(e) => e.preventDefault()}
                                                            />
                                                            <div className='videoBtnIcon'></div>
                                                        </div>
                                                    </Col>

                                                    <Col xl={7} lg={7} md={7} sm={7} xs={7}>
                                                        <h6>{item.title}</h6>
                                                        {item?.description.length > 60 ? (
                                                            <p
                                                                className='text mb-0 fs-12 text-break'
                                                                dangerouslySetInnerHTML={{
                                                                    __html: `${item?.description?.slice(0, 60)}...`,
                                                                }}
                                                            />
                                                        ) : (
                                                            <p
                                                                className='text mb-0 fs-12'
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item?.description,
                                                                }}
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            )}

            {showShare && (
                <ShareModel
                    show={showShare}
                    handleClose={() => setShowShare(false)}
                    id={videoList[currentVideo]?.participant_id}
                />
            )}
        </>
    );
};

export default Entries;
