import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';

import CentralTownLogo from '../../assets/images/central-town.jpg';
import SwarajLogo from '../../assets/images/swaraj.jpg';
import AmityLogo from '../../assets/images/amity.jpg';
import XuvLogo from '../../assets/images/xuv.jpg';

import '../../assets/css/sponsor-style.css';

const sponsor = [
    {
        id: 1,
        title: 'Title Partner- Swaraj Tractors',
        img: SwarajLogo,
        link: 'https://www.swarajtractors.com/',
    },
    { id: 2, title: 'Presenting Partner – Amity University', img: AmityLogo, link: 'https://amity.edu/mohali/' },
    { id: 4, title: 'Powered By - Central Town', img: CentralTownLogo, link: 'https://www.centraltown.in/' },
    { id: 3, title: 'Co Powered By – Mahindra XUV 700', img: XuvLogo, link: 'https://auto.mahindra.com/suv/xuv700' },
];

const Sponsor = () => {
    useEffect(() => {
        document.title = 'Sponsor - Bhangra Premier League - Red FM';
    }, []);

    return (
        <>
            <HeaderSubPages pageName='Sponsor' />
            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8'>
                            <div className='sponsor-main-box'>
                                <div className='row g-0'>
                                    {sponsor.map((item) => {
                                        return (
                                            <div className='col-6' key={item.id}>
                                                <div className='sponsor-single-box'>
                                                    <div className='sponsor-img-rounded'>
                                                        <a href={item.link} target='_blank' rel='noreferrer'>
                                                            <img
                                                                src={item.img}
                                                                className='img-fluid'
                                                                alt={item.title}
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className='content-bottom'>
                                                        <a href={item.link} target='_blank' rel='noreferrer'>
                                                            <h3 className='sponsor-name'>{item.title}</h3>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Sponsor;
