import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useAuthContext } from '../../context/auth.context';
import { useUserContext } from '../../context/user-context';
import PhoneIcon from '../../assets/icons/phone.svg';
import service, { methods } from '../../service';

const schema = yup
    .object({
        phone: yup
            .string()
            .matches(/^\d{10}$/, 'Enter a valid 10-digit mobile number')
            .required('Mobile number is required'),
        tc: yup.boolean().oneOf([true], 'Please check the Terms & Conditions and Privacy Policy'),
        isAbove18: yup.boolean().oneOf([true], 'Please confirm I am above 18 years'),
    })
    .required();

const StepOne = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { handleClose, isVote, handleNextStepVote, isParticipate, handleNextStepParticipate, setStepOneDetails } =
        useAuthContext();
    const { register, handleSubmit, formState } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const { setToken } = useUserContext();
    const navigate = useNavigate();
    const { errors } = formState;

    const onSubmit = async (data) => {
        if (isVote) {
            const isSuccess = await handleSentOtp(data, false);
            if (isSuccess) {
                handleNextStepVote(2);
            }
        }

        if (isParticipate) {
            const isSuccess = await handleSentOtp(data, true);
            if (isSuccess) {
                handleNextStepParticipate(2);
            }
        }
    };

    const handleSentOtp = async (data, forParticipation) => {
        setIsLoading(true);
        try {
            const res = await service({
                url: 'otp/send/',
                data: { phone: data.phone, forParticipation },
                method: methods.POST,
            });
            setToken(res.payload.jwtToken);
            setStepOneDetails({ phone: res.payload.phone, accessToken: res.payload.accessToken });
            return true;
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
            console.error(`Error :: handleSentOtp :: ${error}`);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const handleNavigateToTc = () => {
        handleClose();
        navigate('terms-and-conditions');
    };
    const handleNavigateToPP = () => {
        handleClose();
        navigate('privacy-policy');
    };


    return (
        <div className='content-box'>
            <div className='row justify-content-center align-items-center'>
                <div className='col-lg-12'>
                    <div className='content-top mb-3'>
                        <div className='text-center' onClick={handleClose}>
                            <span className='close-login-big-dash'></span>
                        </div>
                        <h2 className='text-white'>
                            <span className='d-block'>Login</span>
                        </h2>
                    </div>
                    <div className='login-form-box'>
                        <form onSubmit={handleSubmit(onSubmit)} className='d-block'>
                            <div className='form-floating input-with-icon mb-3 d-block'>
                                <img src={PhoneIcon} className='img-fluid' alt='arrow-down' />
                                <input type='number' className='form-control' {...register('phone')} placeholder=' ' />
                                <label htmlFor='EmailId'>Mobile No.</label>
                                {errors.phone && <p className='mt-2 text-white fs-14'>{errors.phone.message}</p>}
                            </div>

                            <div className='form-check mb-2'>
                                <input className='form-check-input border-dark' type='checkbox' {...register('tc')} />
                                <label className='form-check-label fs-14'>
                                    I have read and agreed to{' '}
                                    <span className='text-yellow cursor-pointer' onClick={handleNavigateToTc}>
                                        Terms & Conditions
                                    </span>{' '}
                                    and <Link to='/privacy-policy' className='text-yellow' onClick={handleNavigateToPP}>Privacy Policy</Link>
                                </label>
                                {errors.tc && <p className='mt-2 text-white fs-12'>{errors.tc.message}</p>}
                            </div>

                            <div className='form-check mb-2'>
                                <input
                                    className='form-check-input border-dark'
                                    type='checkbox'
                                    {...register('isAbove18')}
                                />
                                <label className='form-check-label fs-14'>I confirm I am above 18 years</label>
                                {errors.isAbove18 && (
                                    <p className='mt-2 text-white fs-12'>{errors.isAbove18.message}</p>
                                )}
                            </div>

                            <button
                                className={`w-100 btn-theme btn-secondary ${isLoading && 'disable-btn'}`}
                                type='submit'
                                disabled={isLoading}
                            >
                                Login
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepOne;
