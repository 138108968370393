import { createContext, useContext, useState } from 'react';
import { useUserContext } from './user-context';
import { VOTE } from '../constants';

const AuthContext = createContext(undefined);

const AuthContextProvider = ({ children }) => {
    const [isVote, setIsVote] = useState(false);
    const [voteStep, setVoteStep] = useState(0);

    const [isParticipate, setIsParticipate] = useState(false);
    const [participateStep, setParticipateStep] = useState(0);

    const [stepOneDetails, setStepOneDetails] = useState({});

    const { user, userType } = useUserContext();

    const handleOpenVote = () => {
        setIsVote(true);
        setVoteStep(1);
        handleCloseParticipate();
    };

    const handleCloseVote = () => {
        setIsVote(false);
        setIsVote(0);
    };

    const handleNextStepVote = (value) => {
        setVoteStep(value);
    };

    const handleOpenParticipate = () => {
        if (userType === VOTE && user) {
            setIsParticipate(true);
            setParticipateStep(3);
            handleCloseVote();
        } else {
            setIsParticipate(true);
            setParticipateStep(1);
            handleCloseVote();
        }
    };

    const handleCloseParticipate = () => {
        setIsParticipate(false);
        setParticipateStep(0);
    };

    const handleNextStepParticipate = (value) => {
        setParticipateStep(value);
    };

    const handleClose = () => {
        handleCloseParticipate();
        handleCloseVote();
    };

    const contextValue = {
        handleOpenVote,
        isVote,
        voteStep,
        handleClose,
        handleNextStepVote,
        handleOpenParticipate,
        participateStep,
        isParticipate,
        handleNextStepParticipate,
        setStepOneDetails,
        stepOneDetails,
    };

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext must be used within a AuthContextProvider');
    }
    return context;
};

export default AuthContextProvider;
