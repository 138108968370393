import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';
import ImgAboutUs from '../../assets/images/about-top.svg';

function AboutUs() {
    useEffect(() => {
        document.title = 'About Us - Bhangra Premier League - Red FM';
    }, []);
    return (
        <>
            <HeaderSubPages pageName='About Us' />
            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <div className='subpage-main-box'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='subpage-single-box'>
                                            <div className='img-box'>
                                                <img src={ImgAboutUs} className='w-100' alt='pf' />
                                            </div>
                                            <div className='content-bottom'>
                                                <h1 className='subpage-title'>About Bhangra Premiere league</h1>
                                                <p>
                                                    Catchy beats, whirls of color, and unbeatable energy: These three
                                                    phrases encapsulate the essence of all that is BHANGRA.
                                                </p>
                                                <p>
                                                    It is a lively and energetic dance style originating in Punjab. The
                                                    dance is full of life and vitality, reflecting the exuberant spirit
                                                    of Punjabi culture. With its lively footwork, high jumps, joyful
                                                    expressions and spirited movements, Bhangra captures the essence of
                                                    Punjab's vibrant and celebratory spirit. Accompanied by the rhythmic
                                                    beats of the dhol, the traditional drum, and the energetic tunes of
                                                    folk songs, Bhangra creates an electrifying atmosphere that enthrals
                                                    both performers and spectators alike. It's a blend of different folk
                                                    dances from across the region, including Sammi, Jhummar, Luddi,
                                                    Giddha, Dhamaal, Sialkot, and many more.
                                                </p>
                                                <p>
                                                    Bhangra premiere league is the biggest bhangra battle across Punjab.
                                                    This battle between the best bhangra teams, giving the audience a
                                                    taste of traditional bhangra, as well as fusion dances in which
                                                    bhangra moves are set to hip-hop and other genres of music.
                                                </p>
                                                <p>
                                                    Bhangra premiere league invite people to participate across India by
                                                    uploading their videos. The audition battle will happen across
                                                    Punjab.
                                                </p>
                                                <h2 className='h4'>How to Participate</h2>
                                                <ul>
                                                    <li>Log in to www.bhangrapremiereleague.in </li>
                                                    <li>To register create your account. </li>
                                                    <li>Click on the participate button. </li>
                                                    <li>Fill your Name, Mobile number, mail id etc </li>
                                                    <li>Select your city for auditions. </li>
                                                    <li>Upload your video. </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* repeat */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUs;
