import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import OTPInput from 'react-otp-input';
import * as yup from 'yup';

import { useAuthContext } from '../../context/auth.context';
import { useUserContext } from '../../context/user-context';
import { PARTICIPANT, VOTE } from '../../constants';
import service, { methods } from '../../service';

const validationSchema = yup.object().shape({
    otp: yup.string().length(6, 'OTP must be 6 characters').required('OTP is required'),
});

const StepTwo = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, setValue, formState, watch } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });
    const { handleClose, isVote, isParticipate, handleNextStepParticipate, stepOneDetails } = useAuthContext();
    const { setToken, setUser, setUserType } = useUserContext();
    const { errors } = formState;

    const onSubmit = async (data) => {
        const otp = data.otp;
        const res = await verifyOtp(otp);
        if (res?.status === 1) {
            if (isVote) {
                setUserType(VOTE);
                setUser(stepOneDetails.phone);
                handleClose();
            }

            if (isParticipate) {
                const res = await verifyOtp(otp);
                setToken(res.payload.jwtToken);
                if (res.payload.nextScreen === 'register-participant') {
                    handleNextStepParticipate(3);
                } else if (res.payload.nextScreen === 'update-participant-details') {
                    handleNextStepParticipate(4);
                } else if (res.payload.nextScreen === 'upload-file') {
                    handleNextStepParticipate(5);
                } else if (res.payload.nextScreen === 'home') {
                    const user = await fetchUserDetails();
                    setUser(user);
                    setUserType(PARTICIPANT);
                    handleClose();
                }
            }
        }
    };

    const fetchUserDetails = async () => {
        try {
            const res = await service({
                url: 'profile/participant/',
                method: methods.GET,
            });
            return res.payload;
        } catch (error) {
            toast.error(error);
            console.error(`Error :: fetchUserDetails :: ${error}`);
        }
    };

    const verifyOtp = async (otp) => {
        setIsLoading(true);
        try {
            const res = await service({
                url: 'otp/verify/',
                data: { ...stepOneDetails, otp },
                method: methods.POST,
            });
            return res;
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
            console.error(`Error :: verifyOtp :: ${error}`);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='content-box'>
            <div className='row justify-content-center align-items-center'>
                <div className='col-lg-12'>
                    <div className='content-top  mb-3'>
                        <div className='text-center' onClick={handleClose}>
                            <span className='close-login-big-dash'></span>
                        </div>
                        <h2 className='text-white'>
                            <span className='d-block'>OTP</span>
                        </h2>
                    </div>
                    <div className='login-form-box'>
                        <form onSubmit={handleSubmit(onSubmit)} className='d-block'>
                            <OTPInput
                                value={watch('otp')}
                                onChange={(value) => setValue('otp', value, { shouldValidate: true })}
                                numInputs={6}
                                containerStyle='form-group d-flex gap-3 my-3'
                                inputStyle='form-control text-center fs-6'
                                renderInput={(props) => <input {...props} />}
                                skipDefaultStyles={true}
                                register={register}
                                inputType='number'
                            />
                            {errors.otp && <p className='mt-2 text-white'>{errors.otp.message}</p>}

                            <button
                                className={`w-100 btn-theme btn-secondary ${isLoading && 'disable-btn'}`}
                                type='submit'
                                disabled={isLoading}
                            >
                                Confirm
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepTwo;
