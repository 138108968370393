import React, { useState, useEffect } from 'react';

import HeaderDesktop from '../../components/header/header-desktop';
import { useAuthContext } from '../../context/auth.context';
import { useUserContext } from '../../context/user-context';
import Header from '../../components/header/header-mobile';
import HomeSlider from '../../components/home/home-slider';
import TopBanner from '../../components/home/top-banner';
import '../../assets/css/home-registration-style.css';
import { PARTICIPANT } from '../../constants';
import Loader from '../../components/loader';

const Home = () => {
    const [isFirstVisit, setIsFirstVisit] = useState(true);

    const { handleOpenVote, handleOpenParticipate } = useAuthContext();
    const { userType } = useUserContext();

    useEffect(() => {
        const fakeDataLoading = setTimeout(() => {
            setIsFirstVisit(false);
        }, 2000);

        return () => clearTimeout(fakeDataLoading);
    }, [isFirstVisit]);

    useEffect(() => {
        document.title = 'Bhangra Premier League - Red FM';
    }, []);

    if (isFirstVisit) {
        return <Loader />;
    }

    return (
        <div className='w-100 h-100 m-0 p-0'>
            <Header />
            <HeaderDesktop />
            <div className='home-page-main pt-0 pt-lg-4'>
                <TopBanner />
            </div>
            <section className='home-slider-sec pb-3'>
                <HomeSlider />
            </section>
            {userType !== PARTICIPANT && (
                <section className='pb-3 d-lg-none d-block'>
                    <div className='container'>
                        <div className='row justify-content-center pb-3'>
                            <div className='col-lg-6 text-center'>
                                {!userType && (
                                    <button className='btn-theme btn-primary me-3' onClick={handleOpenVote}>
                                        Login/Vote
                                    </button>
                                )}
                                {(userType !== PARTICIPANT || !userType) && (
                                    <button className='btn-theme btn-secondary' onClick={handleOpenParticipate}>
                                        Participate
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
};
export default Home;
