import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import OpenEnrollmentIcon from '../../assets/icons/open-enrollment.svg';
import ArrowDownIcon from '../../assets/icons/arrow-down-s-line.svg';
import { useAuthContext } from '../../context/auth.context';
import { useUserContext } from '../../context/user-context';
import service, { methods } from '../../service';
import { VOTE } from '../../constants';

const schema = yup
    .object({
        group_name: yup.string().required('Participant Name is required'),
        category_id: yup.string().required('Category is required'),
        state_id: yup.string().required('City is required'),
        city_id: yup.string().required('City is required'),
        contactNumber: yup.string().optional(),
    })
    .required();

const StepFour = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);

    const { register, handleSubmit, formState, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });
    const { handleNextStepParticipate, stepOneDetails } = useAuthContext();
    const { user, userType } = useUserContext();
    const { errors } = formState;
    const selectState = watch('state_id');

    const onParticipateDetails = async (data) => {
        setIsLoading(true);
        delete data.contactNumber;
        try {
            const res = await service({
                url: 'participant/update-details/',
                method: methods.POST,
                data,
            });
            if (res?.status === 1) {
                handleNextStepParticipate(5);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
            console.error(`Error :: onParticipateDetails :: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCategory = async () => {
        try {
            const res = await service({
                url: 'categories/',
                method: methods.GET,
            });
            setCategory(res.payload);
        } catch (error) {
            toast.error(error);
            console.error(`Error :: fetchCategory :: ${error}`);
        }
    };

    const fetchState = async () => {
        try {
            const res = await service({
                url: 'states/',
                method: methods.GET,
            });
            setState(res.payload);
        } catch (error) {
            toast.error(error);
            console.error(`Error :: fetchState :: ${error}`);
        }
    };

    useMemo(() => {
        const find = state.find((item) => item.state_id === Number(selectState));
        if (find?.state_cities.length > 0) {
            setCity(find.state_cities);
            setValue('city_id', undefined, { shouldValidate: true });
        }
    }, [selectState, setValue, state]);

    useEffect(() => {
        fetchCategory();
        fetchState();
    }, []);

    useMemo(() => {
        if (stepOneDetails?.phone) {
            setValue('contactNumber', stepOneDetails?.phone);
        }
        if (userType === VOTE && user) {
            setValue('contactNumber', user);
        }
    }, [setValue, stepOneDetails?.phone, user, userType]);

    return (
        <div className='content-box'>
            <div className='row justify-content-center align-items-start vh-100'>
                <div className='col-lg-12 pb-3'>
                    <div className='content-top  mb-3 pt-5 pt-lg-0'>
                        <div className='steps-dash'>
                            <div className='step active'></div>
                            <div className='step'></div>
                        </div>
                        <div className='mb-3'>
                            <img src={OpenEnrollmentIcon} className='img-fluid' alt='OpenEnrollmentIcon' />
                        </div>
                        <h2 className='text-white'>
                            <span className='d-block'>Participant Details</span>
                        </h2>
                    </div>
                    <div className='login-form-box'>
                        <form onSubmit={handleSubmit(onParticipateDetails)} className='d-block'>
                            <div className='form-floating mb-3'>
                                <img src={ArrowDownIcon} className='arrow-select' alt='ArrowDownIcon' />
                                <select className='form-select' {...register('category_id')}>
                                    <option value=''>Select Category</option>
                                    {category.map((item) => {
                                        return (
                                            <option key={item.category_id} value={item.category_id}>
                                                {item.category_name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <label>Category</label>
                                {errors.category_id && <p className='mt-2 text-white'>{errors.category_id.message}</p>}
                            </div>

                            <div className='form-floating mb-3'>
                                <img src={ArrowDownIcon} className='arrow-select' alt='ArrowDownIcon' />
                                <select className='form-select' {...register('state_id')}>
                                    <option value=''>Select State</option>
                                    {state.map((item) => {
                                        return (
                                            <option key={item.state_id} value={item.state_id}>
                                                {item.state_name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <label>State</label>
                                {errors.state_id && <p className='mt-2 text-white'>{errors.state_id.message}</p>}
                            </div>

                            <div className='form-floating mb-3'>
                                <img src={ArrowDownIcon} className='arrow-select' alt='ArrowDownIcon' />
                                <select className='form-select' {...register('city_id')}>
                                    <option value=''>Select City</option>
                                    {city.map((item) => {
                                        return (
                                            <option key={item.city_id} value={item.city_id}>
                                                {item.city_name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <label>City</label>
                                {errors.city_id && <p className='mt-2 text-white'>{errors.city_id.message}</p>}
                            </div>

                            <div className='form-floating mb-3'>
                                <input
                                    type='text'
                                    className='form-control'
                                    {...register('group_name')}
                                    placeholder=' '
                                />
                                <label>Team Participant Names (separate by comma) / Solo</label>
                                {errors.group_name && <p className='mt-2 text-white'>{errors.group_name.message}</p>}
                            </div>

                            <div className='form-floating mb-3'>
                                <input
                                    type='number'
                                    className='form-control'
                                    {...register('contactNumber')}
                                    placeholder=''
                                    disabled
                                />
                                <label>Contact Number</label>
                            </div>

                            <button
                                className={`w-100 btn-theme btn-secondary text-center ${isLoading && 'disable-btn'}`}
                                type='submit'
                                disabled={isLoading}
                            >
                                Next
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepFour;
