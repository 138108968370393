import { Keyboard, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player/lazy';
import React, { useState } from 'react';
import 'swiper/css/pagination';
import 'swiper/css';

import PlayIcon from '../../assets/icons/video-icon.png';
import '../../assets/css/home-slider-sec-style.css';
import VideoPlayerModal from './video-player-modal';

const videos = [
    {
        id: 1,
        url: 'https://youtu.be/hcT1SdpgguY',
    },
    {
        id: 2,
        url: 'https://youtu.be/FZNamRTl7XA',
    },
    {
        id: 3,
        url: 'https://youtu.be/sa_ROGXMTOE?si=fub5udoM6zC9FQma',
    },
    {
        id: 4,
        url: 'https://youtu.be/Chk8nwvILGU',
    },
    {
        id: 5,
        url: 'https://youtu.be/_8mmu1b7Wos',
    },
    {
        id: 6,
        url: 'https://youtu.be/YqB0lDbNM48',
    },
    {
        id: 7,
        url: 'https://youtu.be/BmM2Vpm1kPE',
    },
    {
        id: 8,
        url: 'https://youtu.be/drLxpxeySg4',
    },
];

const HomeSlider = () => {
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState('');

    const handleClose = () => {
        setShow(false);
        setUrl('');
    };

    const handleOpen = (url) => {
        setUrl(url);
        setShow(true);
    };

    return (
        <>
            <section className='home-slider-box-sec'>
                <Swiper
                    slidesPerView='auto'
                    centeredSlides={true}
                    loop={true}
                    spaceBetween={15}
                    grabCursor={true}
                    keyboard={{
                        enabled: true,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Keyboard, Pagination]}
                    className='swiper-home'
                >
                    {videos.map((item) => (
                        <SwiperSlide className='video-box' key={item.id}>
                            <div className='video-container'>
                                <ReactPlayer
                                    url={item.url}
                                    light={true}
                                    width='100%'
                                    height='100%'
                                    className='react-player'
                                    loading='lazy'
                                    playIcon={<img src={PlayIcon} alt='Play Icon' width='40px' />}
                                    onClickPreview={() => handleOpen(item.url)}
                                    playing={false}
                                    onContextMenu={(e) => e.preventDefault()}
                                />
                            </div>
                            <div className='VideoBtnIcon cursor-pointer' onClick={() => handleOpen(item.url)}></div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </section>

            {show && <VideoPlayerModal show={show} handleClose={handleClose} url={url} />}
        </>
    );
};

export default HomeSlider;
