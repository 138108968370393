import { BPL_TOKEN, BPL_USERINFO, BPL_USER_TYPE } from '../constants';

class CustomError extends Error {
    constructor(status, message, responseStatus) {
        super(message);
        this.name = 'CustomError';
        this.status = status;
        this.responseStatus = responseStatus;
        Object.setPrototypeOf(this, CustomError.prototype);
    }
}

const createCustomError = (status, message, responseStatus) => {
    return new CustomError(status, message, responseStatus);
};

export const methods = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    PUT: 'PUT',
};

const service = async ({ url = '', data = null, method = '', params = {} }) => {
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1`;
    const token = localStorage.getItem(BPL_TOKEN);

    let apiUrl = `${baseUrl}/${url}`;
    const queryString = Object.keys(params)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
        .join('&');

    if (method === methods.GET && Object.keys(params).length > 0) {
        apiUrl += `?${queryString}`;
    }

    const headers = {
        'Content-Type': 'application/json',
    };

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    const requestOptions = {
        method,
        headers,
    };

    if (data !== null) {
        if (method === methods.POST && data instanceof FormData) {
            delete requestOptions.headers['Content-Type'];
            requestOptions.body = data;
        } else {
            requestOptions.body = JSON.stringify(data);
        }
    }

    try {
        const response = await fetch(apiUrl, requestOptions);

        const responseData = await response.json();

        if (!response.ok) {
            throw createCustomError(response.status, responseData.message, responseData.status);
        }

        return responseData;
    } catch (error) {
        if (error.status === 401) {
            localStorage.removeItem(BPL_TOKEN);
            localStorage.removeItem(BPL_USER_TYPE);
            localStorage.removeItem(BPL_USERINFO);
            window.location.href = '/';
        } else {
            throw error.message;
        }
    }
};

export default service;
