import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';

const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = 'Privacy Policy - Bhangra Premier League - Red FM';
    }, []);
    return (
        <>
            <HeaderSubPages pageName='Privacy Policy' />
            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8'>
                            <div className='subpage-main-box'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='subpage-single-box'>
                                            <div className='content-bottom'>
                                                <h1 className='subpage-title'>Privacy Policy</h1>
                                                <p>
                                                    In terms of Information Technology Act, 2000, this document is an
                                                    electronic record. Being generated by a computer system it does not
                                                    require any physical or digital signatures.
                                                </p>

                                                <p>
                                                    This document is published in accordance with the provisions of Rule
                                                    3 (1) of the Information Technology (Intermediaries guidelines)
                                                    Rules, 2011 that require publishing the rules and regulations,
                                                    privacy policy and Terms of Use for access or usage of our website
                                                    http://www.redfmindia.com
                                                </p>

                                                <p>Effective Date: [*]</p>

                                                <p>Last Updated: May 6, 2016</p>

                                                <p>1. GENERAL</p>
                                                <p>
                                                    1.1 Digital Radio (Delhi) Broadcasting Limited (“Red FM”) is
                                                    concerned about the privacy of its users (hereinafter referred to as
                                                    "You" "Your" "Yourself") accessing its website located at
                                                    http://www.redfmindia.com ("Website") and has provided this Privacy
                                                    Policy ("Policy") to familiarize You with the manner in which Red FM
                                                    collects, uses and discloses Your information collected through the
                                                    Website.
                                                </p>

                                                <p>
                                                    1.2 “At Red FM, we value the trust placed in us by customers,
                                                    suppliers and colleagues who give us their personal data. Data
                                                    security is one of our highest priorities and we aim to be as clear
                                                    as possible about what we do with personal data and why we do it."
                                                </p>

                                                <h6>
                                                    BY USING THE WEBSITE, YOU AGREE TO THE TERMS AND CONDITIONS OF THIS
                                                    POLICY. IF YOU DO NOT AGREE WITH THE TERMS AND CONDITIONS OF THIS
                                                    POLICY, PLEASE DO NOT PROCEED FURTHER TO USE THIS WEBSITE.
                                                </h6>

                                                <h6>2. SCOPE OF PRIVACY POLICY</h6>

                                                <p>
                                                    This Privacy Policy applies to information, including contact
                                                    information, collected about you by Red FM. Please review this
                                                    Privacy Policy carefully. In addition, please review our Terms of
                                                    Use, which governs your use of the Services.
                                                </p>

                                                <h6>3. INFORMATION AND USAGE</h6>

                                                <p>
                                                    3.1 This Privacy Policy explains how Red FM collects information,
                                                    classifies such information into information that is directly
                                                    provided by the users of the Website and information that s
                                                    automatically collected by Red FM which may be personal and
                                                    non-personal information, classified as mandatory or voluntary and
                                                    uses, discloses and protects such information through the Website.
                                                </p>

                                                <p>
                                                    <u>A. Information You Provide Directly To Us:</u>
                                                </p>

                                                <p>
                                                    You are not required to provide information about yourself when you
                                                    visit or browse through our Website. We do not collect
                                                    personally-identifying information on our Website unless you choose
                                                    to provide that information to us for using any Service or for the
                                                    activities mentioned in this Section.
                                                </p>

                                                <p>We may ask you to provide certain personal information when you:</p>

                                                <p>(a) register for a contest;</p>
                                                <p>(b) sign up for an email list;</p>
                                                <p>(c) request information;</p>
                                                <p>(d) purchase products or services, if any;</p>
                                                <p>
                                                    (e) submit content or user submission that may be used on the
                                                    Website or for any other purpose
                                                </p>
                                                <p>(f) participate in a forum, bulletin board or chat;</p>
                                                <p>(g) become a member;</p>
                                                <p>(h) make a financial contribution;</p>
                                                <p>(i) register for an account; or</p>
                                                <p>(j) fill out a survey;</p>
                                                <p>
                                                    (k) any other activity on the Website for which such information is
                                                    required
                                                </p>

                                                <p>
                                                    Red FM may ask you for different types of information when you
                                                    register for certain Services, access various content or provide
                                                    certain content on the Website or features, or contact the Services
                                                    directly, including:
                                                </p>

                                                <p>
                                                    · Contact information, such as name, email address, postal address,
                                                    phone number, facsimile number, and mobile number;
                                                </p>

                                                <p>
                                                    · User name, password, and password reminder questions and answers;
                                                </p>

                                                <p className='ms-5'>Age and date of birth;</p>

                                                <p>
                                                    · Other demographic information, such as gender, job information,
                                                    and postal code;
                                                </p>

                                                <p>
                                                    · Public information, including profile information, you provide to
                                                    Red FM Services for publication, such as your user name, comments,
                                                    likes, interests, status, pictures and the address of your website;
                                                </p>

                                                <p>
                                                    · Communications preferences, such as which newsletters you would
                                                    like to receive;
                                                </p>

                                                <p className='ms-5'>Search queries;</p>

                                                <p>
                                                    · Information posted in community discussions and other interactive
                                                    online features;
                                                </p>

                                                <p>· Correspondence you send to us; and</p>

                                                <p>
                                                    · Information collected about you offline (including via facsimile,
                                                    postal mail, or in person for sweepstakes entrance forms).
                                                </p>

                                                <p>
                                                    · Information collected about you when you send us your user content
                                                    or submission including videos, pictures, name, voice, likeness etc.
                                                </p>

                                                <p>
                                                    <u>B. Demographic and Profile Information</u>
                                                </p>

                                                <p>
                                                    We occasionally conduct online surveys, sometimes in association
                                                    with a contest or other offer. These surveys may ask you for
                                                    demographic information such as ZIP code, age, education or income
                                                    level. This information is used in an aggregate form to help us
                                                    understand our audiences and improve our services. Demographic and
                                                    profile information may be shared with selected third-party
                                                    organizations, only on an aggregate basis, to describe the nature of
                                                    our online audiences, as well as to further understand our
                                                    audience’s behavior so that we may better serve them.
                                                </p>

                                                <p>
                                                    <u>C. Information we collect automatically</u>
                                                </p>

                                                <p>Red FM may collect certain information automatically, including:</p>
                                                <p>· Your browser type and operating system;</p>
                                                <p>
                                                    · Your Internet Protocol (IP) address, which is the number
                                                    automatically assigned to your computer whenever you access the
                                                    Internet and that can sometimes be used to derive your general
                                                    geographic area;
                                                </p>
                                                <p>· Websites you visited before and after visiting our Website;</p>
                                                <p>
                                                    · Web pages and advertisements you view and links you click on
                                                    within Services;
                                                </p>
                                                <p>
                                                    · Unique identifiers, including mobile device identification
                                                    numbers, that can identify the physical location of such devices in
                                                    accordance with applicable law;
                                                </p>
                                                <p>
                                                    · Information collected through cookies, web beacons and other
                                                    similar technologies;
                                                </p>
                                                <p>
                                                    · Information about your interactions with audio and video content,
                                                    such as the type of content listened to (including music
                                                    applications such as iTunes, Spotify and Last.fm) and content
                                                    viewed, and information about your interactions with email messages,
                                                    such as the links clicked on and whether the messages were opened or
                                                    forwarded;
                                                </p>
                                                <p>
                                                    · Upon request, your bandwidth speed and information about the
                                                    software programs installed on your computer; and
                                                </p>
                                                <p>· Standard server log information.</p>

                                                <p>
                                                    <u>D. Information Red FM obtains from other sources</u>
                                                </p>
                                                <p>
                                                    Where permitted by applicable law, we may collect information about
                                                    you from other sources, including through interactive applications
                                                    (e.g., mobile devices, third party services, and embedded audio and
                                                    video players), from co-branded partners and websites (for example,
                                                    on our Facebook pages), and from commercially available sources
                                                    (such as data aggregators and public databases).
                                                </p>
                                                <p>
                                                    This Privacy Policy does not cover the practices of third parties,
                                                    including those that may disclose information to Us.
                                                </p>

                                                <p>
                                                    <u>E. Combining Information</u>
                                                </p>

                                                <p>
                                                    Please note that all of the information we collect about you may be
                                                    combined, including to help us tailor our communications to you and
                                                    to develop rich online content and services.
                                                </p>

                                                <h6>4. USE OF INFORMATION</h6>

                                                <p>4.1 We generally use other information we collect about you to:</p>

                                                <p>
                                                    (a) verify your identity, manage a contest, gather your editorial
                                                    comments and feedback;
                                                </p>
                                                <p>(b) contact you for additional information;</p>
                                                <p>
                                                    (c) analyze the use of Services and information about visitors to
                                                    our Website to understand and improve our offerings and, in
                                                    accordance with our sharing policies, discussed below, to produce
                                                    anonymous or aggregated data and statistics that might help third
                                                    parties develop their own products and service offerings;
                                                </p>
                                                <p>
                                                    (d) fulfill your requests for services, and information, including
                                                    to send you electronic newsletters, authorize a purchase, and
                                                    complete any transaction that you have requested;
                                                </p>
                                                <p>
                                                    (e) enable you to participate in features such as surveys, polls,
                                                    sweepstakes and message boards;
                                                </p>
                                                <p>
                                                    (f) customize the content you see when you visit our Website and use
                                                    our Services;
                                                </p>
                                                <p>(g) Develop and provide advertising tailored to your interests;</p>
                                                <p>
                                                    (h) Prevent potentially prohibited or illegal activities and
                                                    otherwise in accordance with the Terms of Use; and
                                                </p>
                                                <p>
                                                    (i) For any other purposes disclosed to you at the time we collect
                                                    your information or pursuant to your consent.
                                                </p>
                                                <p>
                                                    4.2 We may also use this information for marketing and promotional
                                                    purposes. By providing this information, you may receive an
                                                    occasional email or letter from us. You will be given the option to
                                                    opt-out of any such listing or have your information removed from a
                                                    list as set forth in this Privacy Policy. We do not share, trade,
                                                    rent or sell your physical or email addresses with third-parties.
                                                </p>
                                                <h6>5. DISCLOSURE OF INFORMATION COLLECTED</h6>

                                                <p>
                                                    5.1 Red FM is committed to maintaining your trust and we want you to
                                                    understand when and with whom we may share information collected
                                                    about you.
                                                </p>
                                                <p>
                                                    <u>5.2 With Your Consent</u>
                                                </p>
                                                <p>
                                                    With your consent, we may share your contact information with third
                                                    party marketing partners.
                                                </p>

                                                <p>
                                                    <u>5.3 Authorized Third Party Service Providers</u>
                                                </p>

                                                <p>
                                                    We share your contact information with third party vendors who help
                                                    us with specialized services, including customer support, email and
                                                    text message deployment, business analytics, marketing, suppression
                                                    list management, and data processing. These third parties are
                                                    allowed to use your contact information to help us provide our
                                                    services and not for any other purpose. Also, authorized third party
                                                    service providers may collect non-contact information about your
                                                    visits to our Services with cookies, web beacons and other similar
                                                    technologies that may be used to deliver advertisements tailored to
                                                    your interests.
                                                </p>

                                                <p>
                                                    <u>5.4 Sweepstakes, Contests, or Promotions</u>
                                                </p>

                                                <p>
                                                    When you choose to enter a sweepstakes, contest, or other promotion,
                                                    and in accordance with the terms and conditions of the promotions,
                                                    your information may be disclosed to our sponsors and to third
                                                    parties who help administer the promotion, including in connection
                                                    with winner selection prize fulfillment and aggregated data
                                                    analysis. Your information also may be disclosed as required by law,
                                                    such as on a winners list. Further, by entering a promotion, you are
                                                    agreeing to the official rules that govern that promotion, including
                                                    allowing our sponsors to use your name, voice, and likeness in
                                                    advertising and marketing associated with the promotion in
                                                    accordance with applicable law. All terms applicable to the
                                                    particular promotion will be made available to you at the time you
                                                    enter the promotion.
                                                </p>

                                                <p>
                                                    <u>5.5 Co-Branded Partners</u>
                                                </p>

                                                <p>
                                                    Co-Branded Partners are third parties with whom Red FM may offer
                                                    jointly a service or feature. You will be able to tell when you are
                                                    accessing a service or feature offered by a Co-Branded Partner,
                                                    because the Co-Branded Partner's name will be featured prominently.
                                                    You may be asked to provide information about yourself to register
                                                    for a service offered by a Co-Branded Partner.
                                                </p>

                                                <p>
                                                    <u>5.6 Business Transfers</u>
                                                </p>

                                                <p>
                                                    We may share your information in connection with a substantial
                                                    corporate transaction, such as the sale of a radio or television
                                                    station participating in the Service, a merger, consolidation, asset
                                                    sale, or in the unlikely event of bankruptcy.
                                                </p>

                                                <p>
                                                    <u>5.7 Legal Requirements</u>
                                                </p>
                                                <p>
                                                    We may disclose information about our users, including contact
                                                    information, to respond to subpoenas, court orders, legal process,
                                                    and other law enforcement measures, and to comply with other legal
                                                    obligations, such as FCC requirements.
                                                </p>

                                                <p>
                                                    <u>5.8 Protect Website</u>
                                                </p>

                                                <p>
                                                    We may disclose information to protect and defend the legal rights,
                                                    interests, and safety of Services, other Red FM companies, and their
                                                    employees, agents, and contractors (including enforcing our
                                                    agreements); to protect the safety and security of users of the
                                                    Services and members of the public; and as otherwise disclosed in
                                                    the Terms of Use.
                                                </p>

                                                <p>
                                                    Please note that we may share information with advertisers as
                                                    described in our overview of Red FM Online Advertising.
                                                </p>

                                                <p>
                                                    In addition, information collected about your interactions with
                                                    audio and video content may be shared with third parties, including
                                                    third party social networking services such as Facebook, and video
                                                    measurement and subscription services, as described in our overview
                                                    of video services and social networking.
                                                </p>

                                                <p>
                                                    Finally, we also may share aggregated or anonymized information with
                                                    third parties to help us develop content and services we hope will
                                                    interest you or to help these third parties develop their own
                                                    product and service offerings, including targeted marketing, as
                                                    described in our overview of third party online advertising.
                                                </p>

                                                <h6>6. CORRECTION / REMOVAL OF PERSONAL INFORMATION</h6>

                                                <p>
                                                    Please direct all requests for correction or removal of your
                                                    personal information to us using the contact information below. If
                                                    you want to correct your personal information on file, you may
                                                    notify us at any time by writing to us via the contact information
                                                    below. Notwithstanding the above, you acknowledge and understand
                                                    that it may be impossible to delete personal information entirely,
                                                    such as personal information existing in backups and other records.
                                                    This will not remove any Content intended for public display on the
                                                    Sites.
                                                </p>

                                                <h6>7. YOUR CHOICES</h6>

                                                <p>
                                                    7.1 You can choose to enjoy content and features on Red FM and
                                                    Services without providing us directly with any information about
                                                    you; however, as described above, some information may be collected
                                                    automatically or be requested so that you can take advantage of
                                                    certain features and services offered on Red FM.
                                                </p>
                                                <p>
                                                    7.2 You can opt out of receiving commercial email or text messages
                                                    from a particular Service or other service by following the
                                                    instructions contained in any such message or by contacting us
                                                    directly. Please note that even if you unsubscribe to commercial
                                                    messages, we may still need to send you communications relating to
                                                    your use of the Services, such as service announcements.
                                                </p>
                                                <p>
                                                    7.3 You have choices about whether cookies and other similar
                                                    technologies are placed on your computer or mobile device. Finally,
                                                    you also have choices about the collection and use of your
                                                    information by third parties to display relevant advertisements, as
                                                    described in the section on Third Party Online Advertising.
                                                </p>
                                                <h6>8. PROTECTION OF INFORMATION</h6>
                                                <p>
                                                    We are committed to protecting your information. We have adopted
                                                    commercially reasonable technical, administrative, and physical
                                                    procedures to help protect your information from loss, misuse,
                                                    unauthorized access and alteration. Please note that no data
                                                    transmission or storage can be guaranteed to be 100% secure. We want
                                                    you to feel confident using our Website and Services, but we cannot
                                                    ensure or warrant the security of any information you transmit to
                                                    us.
                                                </p>
                                                <h6>9. PROTECTION OF CHILDREN</h6>
                                                <p>
                                                    Our Website is intended for a general audience and do not knowingly
                                                    collect or store contact information about children under the age of
                                                    18. Some Services may ask for your full date of birth to ensure that
                                                    they are not collecting information from children under 18 years or
                                                    to identify when additional steps may need to be taken when
                                                    information has been collected from children under 18.
                                                </p>

                                                <p>
                                                    Red FM can collect information from children from are at least 13
                                                    years of age and below 18 years of age when it has been given under
                                                    supervision of and by a parent or legal guardian who agrees to be
                                                    bound by this Agreement has actual knowledge the act.
                                                </p>

                                                <h6>10. USER SUBMISSION</h6>

                                                <p>
                                                    Some Red FM Services enable users to submit their own content for
                                                    use in contests, blogs, over-the-air radio and television
                                                    broadcasts, online audio and video streams, message boards, and
                                                    other functions. Publication of user-generated content on the
                                                    Website is subject to our Terms of Use. Please remember that any
                                                    information you disclose becomes public information and to exercise
                                                    caution when choosing to disclose your contact, financial, and other
                                                    information in a submission. We cannot prevent such information from
                                                    being used in a manner that may violate this Privacy Policy, the
                                                    law, or your personal privacy and safety. You should also be aware
                                                    that we may also engage a third party to provide some of the
                                                    services associated with the blogs, message boards and similar
                                                    functions. This third party will collect and host the information
                                                    that you submit to the Services, but will not use any of this
                                                    information for purposes other than providing the service.
                                                </p>

                                                <h6>11. THIRD PARTY WEBSITES AND SERVICES</h6>

                                                <p>
                                                    Please note that some Services may contain links to other websites
                                                    and services. We are not responsible for the privacy practices of
                                                    those websites, and we recommend that you review the privacy
                                                    policies of each website that you visit. Any information that you
                                                    disclose on the third party website will be governed by that
                                                    website's privacy policy and Red FM has no responsibility of the
                                                    same.
                                                </p>

                                                <h6>12. CHANGES TO THIS PRIVACY POLICY</h6>

                                                <p>
                                                    We may update this Privacy Policy to reflect changes in our
                                                    practices and service offerings. If we modify our Privacy Policy, we
                                                    will update the “Last Modified Date" and such changes will be
                                                    effective upon posting. If we make any material changes in the way
                                                    we use your information, we will notify you by email through the
                                                    email address you most recently provided to us or by posting a
                                                    prominent notice of the changes on the Website.
                                                </p>

                                                <h6>13. COOKIES AND OTHER SIMILAR TECHNOLOGIES</h6>

                                                <p>
                                                    13.1 Cookies are small amounts of data that are stored in separate
                                                    files within your computer's Internet browser. Cookies are accessed
                                                    and recorded by the websites you visit, and by the companies that
                                                    show advertisements on these websites, so that they can recognize
                                                    the same browser.
                                                </p>

                                                <p>
                                                    13.2 Red FM may use cookies and other similar technologies for the
                                                    following general purposes:
                                                </p>

                                                <p>
                                                    · To help us recognize your browser as a previous visitor and to
                                                    save and remember any preferences that may have been set while your
                                                    browser was visiting one of our Services.
                                                </p>

                                                <p>
                                                    · To help us customize the content and advertisements you are shown
                                                    while visiting us
                                                </p>

                                                <p>
                                                    · To help measure and research the effectiveness of our online
                                                    content, features, advertisements and other communications.
                                                </p>

                                                <p>
                                                    · Our Website may also allow third parties to place their own
                                                    cookies within your browser in order to serve you relevant
                                                    advertising online, to help us measure traffic and effectiveness as
                                                    described above, to provide you with access to social media
                                                    networks, functionality and services, and to allow us to conduct
                                                    surveys and research in which you agree to participate.
                                                </p>

                                                <p>
                                                    13.3 Also, authorized third party service providers may collect
                                                    non-contact information about your visits to our Services with
                                                    cookies, web beacons and other similar technologies that may be used
                                                    to deliver advertisements or marketing tailored to your interests.
                                                    These third parties may collect information about your online
                                                    activities over time and across different websites.
                                                </p>

                                                <h6>14. AS IS BASIS INFORMATION</h6>

                                                <p>
                                                    Except as otherwise expressly stated with respect to our services,
                                                    all contents of the Website are offered on an "as is" basis without
                                                    any warranty whatsoever either express or implied. Red FM makes no
                                                    representations, express or implied, including without limitation
                                                    implied warranties of merchantability and fitness for a particular
                                                    purpose.
                                                </p>

                                                <p>
                                                    Red FM does not guarantee the functions contained in the Website
                                                    will be uninterrupted or error-free, that this site or its server
                                                    will be free of viruses or other harmful components, or defects will
                                                    be corrected even if Red FM is aware of them.
                                                </p>

                                                <h6>15. COPYRIGHT AND TRADEMARK</h6>

                                                <p>
                                                    Unless otherwise noted, all materials on this Website are protected
                                                    as the copyrights, trade dress, trademarks and/ or other
                                                    intellectual properties owned by Red FM or by other parties that
                                                    have licensed their material to Red FM.
                                                </p>

                                                <p>All rights not expressly granted are reserved.</p>

                                                <h6>16. PERSONAL USE</h6>

                                                <p>
                                                    Your use of the materials included on this site is for informational
                                                    and entertainment purposes only. You agree you will not distribute,
                                                    publish, transmit, modify, display or create derivative works from
                                                    or exploit the contents of this site in any way. You acknowledge the
                                                    unauthorized use of the contents could cause irreparable harm to Red
                                                    FM and that in the event of an unauthorized use, Red FM shall be
                                                    entitled to an injunction in addition to any other remedies
                                                    available at law or in equity.
                                                </p>

                                                <h6>17. FEEDBACK AND SUBMISSIONS</h6>

                                                <p>
                                                    17.1 You agree you are and shall remain solely responsible for the
                                                    contents of any submissions you make, and you will not submit
                                                    material that is unlawful, defamatory, abusive or obscene. You agree
                                                    that you will not submit anything to the site that will violate any
                                                    right of any third party, including copyright, trademark, privacy or
                                                    other personal or proprietary right(s).
                                                </p>

                                                <p>
                                                    17.2 While we appreciate your interest in Red FM, we do not want and
                                                    cannot accept any ideas you consider to be proprietary regarding
                                                    designs, product technology or other suggestions you may have
                                                    developed. Consequently, any material you submit to this site will
                                                    be deemed a grant of a royalty free non-exclusive right and license
                                                    to use, reproduce, modify, display, transmit, adapt, publish,
                                                    translate, create derivative works from and distribute these
                                                    materials throughout the universe in any medium and through any
                                                    methods of distribution, transmission and display whether now known
                                                    or hereafter devised. In addition, you warrant that all so-called
                                                    "moral rights" have been waived
                                                </p>

                                                <h6>18. GENERAL PROVISIONS</h6>

                                                <p>
                                                    18.1 By accessing the Website, You have agreed to the terms set out
                                                    in this Policy. This Policy should be at all times read along with
                                                    the Terms of Use of the Website.
                                                </p>

                                                <p>
                                                    18.2 Unless stated otherwise, the Policy applies to all information
                                                    that Red FM has about You and Your account.
                                                </p>

                                                <p>
                                                    By using this Website, content and services provided on the Website,
                                                    You agree and acknowledge that Your Information collected through
                                                    the Website may be transferred across national boundaries and stored
                                                    and processed in any country around the world.
                                                </p>

                                                <p>
                                                    18.3 If You choose to visit the Website, Your visit and any dispute
                                                    over privacy is subject to this Policy and the Website's Terms of
                                                    Use. In addition to the foregoing, any disputes arising under this
                                                    Policy shall be governed by the laws of India.
                                                </p>

                                                <h6>19. COMPLAINTS</h6>

                                                <p>
                                                    19.1 If you have a legal complaint regarding the Services, Website
                                                    or this Policy, or wish to receive further information regarding use
                                                    of the Services, please contact us on [*]. You may also submit
                                                    comments using the postal address below:
                                                </p>

                                                <p>[*]</p>

                                                <p>
                                                    Digital Radio (Delhi) Broadcasting Limited, D-45, Sector - 2, Noida,
                                                    UP - 201301
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PrivacyPolicy;
