import { createContext, useContext, useState } from 'react';

import { BPL_TOKEN, BPL_USERINFO, BPL_USER_TYPE } from '../constants';

const UserContext = createContext(undefined);

const UserContextProvider = ({ children }) => {
    const storedUser = JSON.parse(localStorage.getItem(BPL_USERINFO));
    const storedToken = localStorage.getItem(BPL_TOKEN);
    const storedUserType = localStorage.getItem(BPL_USER_TYPE);

    const [token, setTokenInternal] = useState(storedToken || null);
    const [userType, setUserTypeInternal] = useState(storedUserType || null);
    const [user, setUserInternal] = useState(storedUser || null);

    const setToken = (token) => {
        setTokenInternal(token);
        localStorage.setItem(BPL_TOKEN, token);
    };

    const setUserType = (userType) => {
        setUserTypeInternal(userType);
        localStorage.setItem(BPL_USER_TYPE, userType);
    };

    const setUser = (user) => {
        setUserInternal(user);
        localStorage.setItem(BPL_USERINFO, JSON.stringify(user));
    };

    const logout = () => {
        setTokenInternal(null);
        setUserTypeInternal(null);
        setUserInternal(null);
        localStorage.removeItem(BPL_TOKEN);
        localStorage.removeItem(BPL_USER_TYPE);
        localStorage.removeItem(BPL_USERINFO);
    };

    const contextValue = {
        setToken,
        setUserType,
        setUser,
        logout,
        token,
        userType,
        user,
    };

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserContextProvider');
    }
    return context;
};

export default UserContextProvider;
