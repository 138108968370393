import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { useAuthContext } from './context/auth.context';
import StepThree from './components/auth/step-three';
import StepFour from './components/auth/step-four';
import StepFive from './components/auth/step-five';
import StepTwo from './components/auth/step-two';
import StepSix from './components/auth/step-six';
import StepOne from './components/auth/step-one';
import CrossIcon from './assets/icons/x.svg';
import Footer from './components/footer';
import Router from './router';

ReactGA.initialize(process.env.REACT_APP_GA_ID);

function App() {
    const { isVote, voteStep, handleClose, isParticipate, participateStep } = useAuthContext();
    const location = useLocation();

    useEffect(() => {
        if (isVote || isParticipate) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isParticipate, isVote]);

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname + location.search,
            title: location.pathname,
        });
    }, [location]);

    return (
        <>
            {/* Router */}
            <Router />

            {/* Vote step */}
            <div className={`content-registration ${isVote ? 'show-registration' : 'hide-registration'}`}>
                {voteStep === 1 && <StepOne />}
                {voteStep === 2 && <StepTwo />}

                <div className='overly-bg'></div>
                <button className='text-x' onClick={handleClose}>
                    <img src={CrossIcon} className='img-fluid' alt='cross-icon' />
                </button>
            </div>

            {/* Participation step */}
            <div className={`content-registration ${isParticipate ? 'show-registration' : 'hide-registration'}`}>
                {participateStep === 1 && <StepOne />}
                {participateStep === 2 && <StepTwo />}
                {participateStep === 3 && <StepThree />}
                {participateStep === 4 && <StepFour />}
                {participateStep === 5 && <StepFive />}
                {participateStep === 6 && <StepSix />}

                <div className='overly-bg'></div>
                <button className='text-x' onClick={handleClose}>
                    <img src={CrossIcon} className='img-fluid' alt='cross-icon' />
                </button>
            </div>

            <Footer />
        </>
    );
}

export default App;
