import { Row, Col } from 'react-bootstrap';
import React from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
} from 'react-share';

import CrossIcon from '../../assets/icons/x.svg';

const ShareModel = ({ show, handleClose, id }) => {
    const domain = `http://${window.location.host}`;
    const shareUrl = `${domain}/entries/${id}`;

    return (
        <div className={`content-registration ${show ? 'show-registration' : 'hide-registration'}`}>
            <div className='content-box'>
                <Row className='justify-content-center text-center'>
                    <Col>
                        <WhatsappShareButton url={shareUrl}>
                            <WhatsappIcon size={48} round />
                        </WhatsappShareButton>
                    </Col>
                    <Col>
                        <FacebookShareButton url={shareUrl}>
                            <FacebookIcon size={48} round />
                        </FacebookShareButton>
                    </Col>
                    <Col>
                        <TwitterShareButton url={shareUrl}>
                            <TwitterIcon size={48} round />
                        </TwitterShareButton>
                    </Col>
                </Row>
            </div>
            <div className='overly-bg'></div>
            <button className='text-x' onClick={handleClose}>
                <img src={CrossIcon} className='img-fluid' alt='cross-icon' />
            </button>
        </div>
    );
};

export default ShareModel;
