import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';
import '../../assets/css/faq-style.css';

const jsonData = [
    {
        id: '02',
        title: 'Is there any cost for the registration process?',
        description: 'No. Registration is free of cost.',
    },
    {
        id: '03',
        title: 'Is there any time limit for uploading the video?',
        description:
            'You can upload a video of a maximum of 2 – 2.5 minutes and the video should not be older than 6 months from the current date.',
    },
    {
        id: '04',
        title: 'Is a dress code required while uploading a video?',
        description: 'No dress code is required at the time of uploading the video for auditions.',
    },
    {
        id: '05',
        title: 'Will I get any confirmation after uploading the video for the auditions?',
        description:
            'Yes. A message will be sent to the registered mobile number with confirmation and venue details for auditions will be shared accordingly.',
    },
    {
        id: '06',
        title: 'What is the minimum age to participate in the Bhangra Premier League?',
        description: 'The minimum age to participate is 6+ years.',
    },
    {
        id: '07',
        title: 'Which are the Audition venues?',
        description: 'There are 4 venues for the auditions i.e. Chandigarh, Amritsar, Jalandhar & Mohali.',
    },
    {
        id: '08',
        title: 'What are the dates & timings for Auditions?',
        description:
            'Dates along with venue details are given on our website. Entry time for auditions is between 10 am and 5 pm strictly.',
    },
    {
        id: '09',
        title: 'Is a dress code required for the auditions?',
        description:
            'Every Participant(s) shall bring their respective costumes and dance pieces of equipment for the audition.',
    },
    {
        id: '10',
        title: 'Can I give multiple auditions?',
        description:
            'If an applicant has been auditioned once, he/she/they can not attend any further rounds of auditions unless selected in the first audition and so required by the organizers.',
    },
    {
        id: '11',
        title: 'Can I participate alone or with the team?',
        description:
            'Yes, you can participate alone also. There are 2 categories – Team of below 6 participants & Team of above 6 participants.',
    },
    {
        id: '12',
        title: 'Can I bring my props for the auditions?',
        description: 'Yes, if required you have to bring your props.',
    },
    {
        id: '13',
        title: 'Why can’t I make any changes?',
        description:
            'After you have completed your registration, or the closing date has passed you cannot make changes- no exceptions.',
    },
    {
        id: '14',
        title: 'Can I participate even If I’m not from Punjab?',
        description:
            'Yes. Participation is open to everyone. But if you are coming from other states then all the expenses are to be borne by the participant.',
    },
    {
        id: '15',
        title: 'For outstation participants will there be any travel and stay provision?',
        description: 'No. All stay and travel expenses are to be borne by the participants.',
    },
    {
        id: '16',
        title: 'Will I get a participation certificate?',
        description: 'Yes.',
    },
    {
        id: '17',
        title: 'Is there any prize money for the winner?',
        description: 'Yes, indeed. Red FM is giving out a whopping amount of 2.5 lakhs to winners!',
    },
];

const Faqs = () => {
    useEffect(() => {
        document.title = 'FAQ - Bhangra Premier League - Red FM';
    }, []);
    return (
        <>
            <HeaderSubPages pageName='FAQ' />
            <section className='mt-3'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8'>
                            <div className='subpage-main-box'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='accordion faq-accordion' id='accordionFaq'>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header collapsed' id='headingOne'>
                                                    <button
                                                        className='accordion-button'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseOne'
                                                        aria-expanded='true'
                                                        aria-controls='collapseOne'
                                                    >
                                                        <div className='number'>01</div>
                                                        How can I participate?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseOne'
                                                    className='accordion-collapse collapse show'
                                                    aria-labelledby='headingOne'
                                                    data-bs-parent='#accordionFaq'
                                                >
                                                    <div className='accordion-body'>
                                                        You can participate by clicking on the registration link and
                                                        filling in your details. Upload your video in MP4, MOV, WMV, or
                                                        AVI format in horizontal form and submit.
                                                    </div>
                                                </div>
                                            </div>
                                            {jsonData.map((item) => (
                                                <div className='accordion-item' key={item.id}>
                                                    <h2 className='accordion-header' id={`heading${item.id}`}>
                                                        <button
                                                            className='accordion-button collapsed'
                                                            type='button'
                                                            data-bs-toggle='collapse'
                                                            data-bs-target={`#collapse${item.id}`}
                                                            aria-expanded='false'
                                                            aria-controls={`collapse${item.id}`}
                                                        >
                                                            <div className='number'>{item.id}</div> {item.title}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id={`collapse${item.id}`}
                                                        className='accordion-collapse collapse'
                                                        aria-labelledby={`heading${item.id}`}
                                                        data-bs-parent='#accordionFaq'
                                                    >
                                                        <div className='accordion-body'>{item.description}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Faqs;
