import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';

import HeaderSubPages from '../../components/header/header-sub-pages';
import ShareModel from '../../components/entries/share-model';
import service, { methods } from '../../service';

import CopyIcon from '../../assets/icons/copy.svg';

function MyVideos() {
    const [showShare, setShowShare] = useState(false);
    const [video, setVideo] = useState({});

    const domain = `http://${window.location.host}`;
    const shareUrl = useMemo(
        () => `${domain}/entries?participant_id=${video?.participant_id}`,
        [domain, video?.participant_id],
    );

    const fetchMyVideos = async () => {
        try {
            const res = await service({
                url: 'profile/participant/videos/',
                method: methods.GET,
            });
            setVideo(res.payload[0]);
        } catch (error) {
            toast.error(error);
            console.error(`Error :: fetchMyVideos :: ${error}`);
        }
    };

    useEffect(() => {
        fetchMyVideos();
    }, []);

    useEffect(() => {
        document.title = 'My Videos - Bhangra Premier League - Red FM';
    }, []);

    return (
        <>
            <HeaderSubPages pageName='My Videos' />

            <Container className='py-5 h-100'>
                <Row className='justify-content-center'>
                    <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                        <div className='big-video-container'>
                            <ReactPlayer
                                url={video?.file_path}
                                width='100%'
                                height='100%'
                                className='react-player'
                                loading='lazy'
                                playsinline
                                controls
                                config={{
                                    vimeo: {
                                        playerOptions: {
                                            play_button_position: 'center',
                                            playsinline: true,
                                            transcript: false,
                                            pip: false,
                                            quality_selector: false,
                                            cc: false,
                                            speed: false,
                                            colors: ['ffd117', 'FF0000', 'FF0000', '000000'],
                                        },
                                    },
                                }}
                            />
                        </div>

                        <Row className='mt-3 mb-5 gy-3'>
                            <Col xl={9} lg={9} md={9} sm={12} xs={12} className='text-white'>
                                <h5>{video?.title}</h5>
                                <p
                                    className='text mb-0 fs-12'
                                    dangerouslySetInnerHTML={{
                                        __html: video?.description,
                                    }}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                <div className='d-flex justify-content-lg-end gap-2'>
                                    <WhatsappShareButton url={shareUrl}>
                                        <WhatsappIcon size={40} round />
                                    </WhatsappShareButton>
                                    <FacebookShareButton url={shareUrl}>
                                        <FacebookIcon size={40} round />
                                    </FacebookShareButton>
                                    <div
                                        className='copy rounded-circle d-flex align-items-center justify-content-center cursor-pointer'
                                        onClick={() => navigator.clipboard.writeText(shareUrl)}
                                    >
                                        <img src={CopyIcon} alt='Copy Icon' width={20} height={20} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            {showShare && (
                <ShareModel show={showShare} handleClose={() => setShowShare(false)} id={video?.participant_id} />
            )}
        </>
    );
}

export default MyVideos;
