import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';

const TermsAndConditions = () => {
    useEffect(() => {
        document.title = 'Terms & Conditions - Bhangra Premier League - Red FM';
    }, []);
    return (
        <>
            <HeaderSubPages pageName='Terms & Conditions' />
            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8'>
                            <div className='subpage-main-box'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='subpage-single-box'>
                                            <div className='content-bottom'>
                                                <h1 className='subpage-title'>Terms and Conditions</h1>
                                                <p>
                                                    This Bhangra Premier League (BPL) is the biggest bhangra battle
                                                    across Punjab. This battle between the best bhangra teams, giving
                                                    the audience a taste of traditional bhangra, as well as fusion
                                                    dances in which bhangra moves are set to hip-hop and other genres of
                                                    music. BPL invites people to participate across India by uploading
                                                    their videos onto the microsite – [www.bhangrapremierleague.in] –
                                                    which is run, managed, and operated by Digital Radio (Delhi)
                                                    Broadcasting Limited, a company duly incorporated under the
                                                    Companies Act, 1956 and having its corporate office at D-45, Sector
                                                    - 2, Noida, UP - 201301, and registered office at: Flat No. 401, 4th
                                                    Floor, Dakha House 18/17, W.E.A. Karol Bagh New Delhi-110005,
                                                    (“Organizer”).
                                                </p>

                                                <p>
                                                    BPL will involve virtual and physical events, whereby you upload
                                                    your videos which would be subject to selection by the judges, and
                                                    eventually the shortlisted team will be called for physical
                                                    auditions, and performances before judges across several locations
                                                    in Punjab, before the final winner is decided.
                                                </p>

                                                <p>
                                                    Please read the terms and conditions of BPL before participating in
                                                    it (hereinafter referred to as the “Event”).
                                                </p>

                                                <h6>1. Agreement to Terms and Conditions -</h6>
                                                <p>
                                                    a) By participating in this Event, Participants fully and
                                                    unconditionally agree to abide by the Terms and Conditions available
                                                    on: www.bhangrapremierleague.in.
                                                </p>

                                                <p>
                                                    b) The Event will be as per the details which have been provided
                                                    within the FAQs which are also available on the BPL website.
                                                </p>

                                                <p>
                                                    c) Organizers reserves its right to change, defer, alter, or cancel
                                                    this Event in part or full, or change any or all terms and
                                                    conditions of the Event, without giving any prior intimation/ notice
                                                    of any kind.
                                                </p>

                                                <p>
                                                    d) Decision of the Organizer in general, and with respect to this
                                                    Event shall be full, final, binding and non-contestable upon the
                                                    Participant.
                                                </p>

                                                <h6>2. Eligibility &amp; Participation -</h6>

                                                <p>
                                                    a) This Event is open to all Indian citizens only above 18 years of
                                                    age, as on the date of submission of the video. As the performances
                                                    uploaded onto the BPL website could also be group performances, your
                                                    submission indicates that you have the consent of each member
                                                    performer in the video. In case there are minors who are part of the
                                                    performances within the video submissions, then you warrant that you
                                                    have the consent of their lawful guardian, parent.
                                                </p>

                                                <p>
                                                    b) Any entry including or depicting anything which is foul,
                                                    indecent, threatening, discriminatory, vulgar, provocative,
                                                    defamatory, sexually explicit, objectionable, Page 2 of 6
                                                    inappropriate or against public policy or public morality, shall
                                                    lead to disqualification of the participants at sole discretion of
                                                    the Organizer.
                                                </p>

                                                <p>
                                                    c) The Event shall commence on 19 th January 2024 for 44 days till 3
                                                    rd March 2024. The physical auditions will be as per the dates which
                                                    are uploaded onto the website, and finale will be held in Chandigarh
                                                    on the 3 rd of March, 2024.
                                                </p>

                                                <p>
                                                    d) The audition venues in the cities will be chosen by the
                                                    Organizer, and the Organizer reserves its right to change the venue,
                                                    date and time of the auditions at its discretion at any point of
                                                    time.
                                                </p>

                                                <p>
                                                    e) The auditions will be between 10 AM to 5 PM on the days chosen at
                                                    such locations, and all participants who have entered the venue
                                                    cannot leave in between, till auditions for that day are completed
                                                    for the particular venue.
                                                </p>

                                                <p>
                                                    f) The registration does not guarantee the selection or
                                                    participation of the applicant in the physical auditions and such
                                                    selection shall be as per the sole discretion of Organizer,
                                                    availability of time and venue. The applicant/legal guardian shall
                                                    not raise and waives any right to question the selection process,
                                                    non-selection of the applicant and/or the selection of any other
                                                    applicant.
                                                </p>

                                                <p>
                                                    g) All the participants must bring their own costumes, and props for
                                                    their own performances. The Organizer will not be responsible for
                                                    making these things available to the participants.
                                                </p>

                                                <p>
                                                    h) As the BPL is open to people from all states, you are free to
                                                    participate, but all travel and accommodation, related expenses will
                                                    be solely borne by you, and the Organizer shall in no manner be
                                                    responsible for any such expenses.
                                                </p>

                                                <p>
                                                    i) The participants must carry their own food, water, refreshments
                                                    and any emergency medication that they may require during the time
                                                    of the auditions, across the venues.
                                                </p>

                                                <p>
                                                    j) A Participant, by participating in this contest hereby represents
                                                    and warrants that all the submissions are their original works and
                                                    no copyright and/or trademark and/or other intellectual property
                                                    rights have been violated by such submission. The content uploaded
                                                    by the Participants should not have been sponsored, endorsed or
                                                    administered by, or associated with any third party.
                                                </p>

                                                <p>
                                                    k) As videos will be containing music/ audio content, it is upon the
                                                    Participants to comply with the necessary licenses, authorizations,
                                                    and the Organizer shall in no way be responsible for any claims made
                                                    by such third parties, whose content may have been used by you.
                                                </p>

                                                <p>
                                                    l) In case of a take down notice, the Organizer shall remove such
                                                    infringing content, and you will be disqualified immediately, and
                                                    become liable for claims of infringement, and violation of
                                                    third-party rights.
                                                </p>

                                                <p>
                                                    m) Each Participant may one entry for their participation in the
                                                    contest, with other or same performers in multiple videos while
                                                    uploading the same onto the BPL website.
                                                </p>

                                                <p>
                                                    n) The Participants represent and warrant that all the information
                                                    provided by them is factual and do not suffer from any falsities. It
                                                    is reiterated that the Organizer will have the full authority to
                                                    disqualify any entry into the Event if falsifications are detected
                                                    in the information provided by the Participants.
                                                </p>

                                                <p>
                                                    o) Entry of participant(s) in one stage of audition shall not
                                                    entitle him/her the entry in finale. The Company reserves its right
                                                    to disqualify and/or exclude, in its sole and absolute discretion,
                                                    any participant from any stage of audition without assigning any
                                                    reason therefore.
                                                </p>

                                                <p>
                                                    p) Your behavior with the judges has to be absolutely cordial, and
                                                    you cannot defame the judges in any manner during or after the
                                                    Event. Your behavior may lead to your disqualification, solely at
                                                    the discretion of the Organizer.
                                                </p>

                                                <p>
                                                    q) You are free to share your videos across social-media pages,
                                                    which belong to you or your friends, and Organizer shall not be
                                                    responsible for any concerns arising out of such voluntary sharing
                                                    on your part. In case you link it to the channels, pages across the
                                                    social media platforms and sites which are mapped to the Organizer,
                                                    and there is any offensive, derogatory, untoward content associated
                                                    with it, the Organizer may take down the video and also disqualify
                                                    your participation.
                                                </p>

                                                <p>
                                                    r) Your participation in the Event signifies your assent to be part
                                                    of any client integrations that the Organizer enters into.
                                                </p>

                                                <p>
                                                    s) Anyone accompanying the participants shall be traveling at their
                                                    own risk, costs and Organizer shall in no manner be responsible for
                                                    them.
                                                </p>

                                                <h6>3. Scoring System -</h6>

                                                <p>
                                                    a) All the videos uploaded onto the BPL website will be reviewed by
                                                    the panel of judges, and the ones which are selected will be
                                                    required to come for a physical audition to be conducted across
                                                    Chandigarh, Amritsar, Jalandhar and Mohali.
                                                </p>

                                                <p>
                                                    b) The exact scoring system will be determined by the Organizer, and
                                                    you may not necessarily have access to the actual scores, but only
                                                    the position you have attained.
                                                </p>

                                                <p>
                                                    c) The Organizers reserves the right to modify the rules and/or the
                                                    terms and conditions from time to time without any prior/public
                                                    notice by posting the applicable terms and conditions on its website
                                                    or otherwise.
                                                </p>

                                                <p>
                                                    d) Upon the audition, if you are selected you may have to
                                                    participate in further auditions, and be proceeded for the final
                                                    rounds. If you fail in the audition at one location, you cannot then
                                                    go ahead to another location for another audition.
                                                </p>

                                                <h6>4. Intellectual Property -</h6>

                                                <p>
                                                    a) All rights, title and interest, including but not limited to the
                                                    Intellectual Property Rights, in the Event material(s) and in any
                                                    and all responses received shall vest solely and exclusively with
                                                    the Organizer at all times.
                                                </p>

                                                <p>
                                                    b) All material submitted in connection with the Event (whether
                                                    written, audio, electronic or visual form, or a combination of
                                                    those) by the Participants are assigned to the Organizer upon
                                                    submission and become the property of the Organizer exclusively. The
                                                    Organizer may use the material in any medium in any reasonable
                                                    manner it sees fit. Copyright in any such material remains the sole
                                                    property of the Organizer.
                                                </p>

                                                <p>
                                                    c) The Organizer will have the right to use photographs, cut outs,
                                                    information pertaining to the Artist, shoot reality bites
                                                    from/related to participant(s), his/her friends and family members,
                                                    personal photographs and other available information.
                                                </p>

                                                <h6>5. General Conditions -</h6>

                                                <p>
                                                    a) The Participant understands and agrees that the selected
                                                    Winner(s) alone shall be entitled to the prizes, subject to all
                                                    terms and conditions mentioned herein, and furnishing of correct and
                                                    complete details as required by the Organizer (including but not
                                                    limited to) in the prescribed manner and to the entire satisfaction
                                                    of the Organizer.
                                                </p>

                                                <p>
                                                    b) The prize can neither be exchanged nor redeemed for cash/ any
                                                    non-monetary consideration nor it is transferable.
                                                </p>

                                                <p>
                                                    c) The prize is subject to the applicable laws of India. The prize
                                                    would be delivered/ handed over to the Winner only upon proper
                                                    identification and verification of the Participant to the full
                                                    satisfaction of the Organizer.
                                                </p>

                                                <p>
                                                    d) Organizers are not responsible for any errors or omissions in the
                                                    terms and conditions contained herein. All information provided in
                                                    the Event is provided on &quot;as-it-is&quot; basis without any
                                                    warranty of any kind. Organizer, its management, directors,
                                                    employees, officers, affiliates or subsidiaries, agents,
                                                    representatives, etc. or any of its sponsors/ partners make no
                                                    representations, and further disclaim themselves from all express,
                                                    implied, and statutory warranties of any kind towards the
                                                    Participants, and any third party with respect to accuracy,
                                                    timelines, completeness, merchantability, or fitness of the Event.
                                                </p>

                                                <p>
                                                    e) All rights, title and interest, including but not limited to
                                                    Intellectual Property Rights in the Event and/or any other
                                                    promotional material(s) shall vest solely and exclusively with
                                                    Organizer at all times. By voluntarily participating in the Event,
                                                    Participant and/or Winner(s) hereby waive off all their rights to
                                                    claim royalty, remuneration or legally challenge due to any such use
                                                    of their personal details by the Organizer.
                                                </p>

                                                <p>
                                                    f) The Participant specifically agree that Organizers, its
                                                    affiliates, its group companies, their employees, officers,
                                                    directors or any other person and/or sponsors shall not be liable
                                                    for any claims/ liabilities/ losses (including legal fees) arising
                                                    out of and in relation to any injury/ damage/ harm/ loss/ death/
                                                    mental or emotional trauma suffered by the Participant in any manner
                                                    whatsoever in connection with the Event and/or the prizes.
                                                </p>

                                                <p>
                                                    g) Organizers shall not be liable to perform any of its obligations
                                                    under the Event or in respect of the Prize where it is unable to do
                                                    so as a result of unforeseen circumstances, Force Majeure conditions
                                                    such as epidemic outbreak, pandemic including but not limited to
                                                    COVID-19, lockdown or circumstances beyond its reasonable control.
                                                </p>

                                                <p>
                                                    h) Organizers are empowered to take a decision in case of any
                                                    conditions, circumstances and scenarios beyond these terms and
                                                    conditions of the Event.
                                                </p>

                                                <p>
                                                    i) Any deterioration in the physical or mental health of the
                                                    participants during the Event shall not make the Organizer liable,
                                                    where the decision of participating in the Event is purely voluntary
                                                    in nature.
                                                </p>

                                                <p>
                                                    j) In case of any untoward incidents, the Organizer retains the
                                                    right to take legal actions against the participants. Where repeated
                                                    warnings, if any, have been issued to the participants who are at
                                                    default, then the Organizer may proceed with initiation of legal
                                                    proceedings, as deemed fit.
                                                </p>

                                                <p>
                                                    k) The Event and the terms and conditions herein shall be governed
                                                    by and construed in accordance with the applicable laws in India.
                                                    All matters with respect to the Event and/or prizes are subject to
                                                    the exclusive jurisdiction of the courts at Delhi only. The Event
                                                    shall be deemed void where prohibited by law.
                                                </p>

                                                <p>
                                                    l) In case of any queries with respect to the Event, please reach
                                                    out to the Organizers at [red.digital@redfm.in].
                                                </p>

                                                <strong>
                                                    These terms and conditions are to be read in conjunction with the
                                                    general terms of use and privacy policy of the Organizer, available
                                                    at – [https://www.redfmindia.in/privacy-policy].
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    {/* repeat */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TermsAndConditions;
