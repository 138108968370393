import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import ArrowDownIcon from '../../assets/icons/arrow-down-s-line.svg';
import { useAuthContext } from '../../context/auth.context';
import { useUserContext } from '../../context/user-context';
import UserIcon from '../../assets/icons/user.svg';
import service, { methods } from '../../service';

const schema = yup
    .object({
        full_name: yup.string().required('Full Name is required'),
        email: yup.string().email('Enter a valid email').required('Email is required'),
        auditionDetails: yup.string().required('Preferred Audition Location is required'),
    })
    .required();

const StepThree = () => {
    const [auditionDetails, setAuditionDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, formState } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });
    const { errors } = formState;

    const { handleNextStepParticipate } = useAuthContext();
    const { setToken } = useUserContext();

    const fetchAuditionDetails = async () => {
        try {
            const res = await service({
                url: 'audition/details/',
                method: methods.GET,
            });
            const modified = res.payload.map((item) => {
                return {
                    label: `${item.location} - ${item.date}`,
                    value: `${item.location} - ${item.date}`,
                    disabled: item.location === 'Chandigarh',
                };
            });
            setAuditionDetails(modified);
        } catch (error) {
            toast.error(error);
            console.error(`Error :: fetchState :: ${error}`);
        }
    };

    const onRegister = async (data) => {
        setIsLoading(true);
        try {
            const res = await service({
                url: 'register/',
                data,
                method: methods.POST,
            });
            if (res?.status === 1) {
                setToken(res.payload.jwtToken);
                handleNextStepParticipate(4);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
            console.error(`Error :: onRegister :: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAuditionDetails();
    }, []);

    return (
        <div className='content-box'>
            <div className='row justify-content-center align-items-center vh-100'>
                <div className='col-lg-12'>
                    <div className='content-top  mb-3 pt-5'>
                        <h2 className='text-white'>
                            <span className='d-block'>Register</span>
                        </h2>
                    </div>

                    <div className='login-form-box'>
                        <form onSubmit={handleSubmit(onRegister)} className='d-block'>
                            <div className='form-floating input-with-icon mb-3'>
                                <img src={UserIcon} className='img-fluid' alt='user-icon' />
                                <input
                                    type='text'
                                    className='form-control'
                                    {...register('full_name')}
                                    placeholder=' '
                                />
                                <label>Full Name</label>
                                {errors.full_name && <p className='mt-2 text-white'>{errors.full_name.message}</p>}
                            </div>

                            <div className='form-floating input-with-icon mb-3'>
                                <img src={UserIcon} className='img-fluid' alt='user-icon' />
                                <input type='email' className='form-control' {...register('email')} placeholder=' ' />
                                <label htmlFor='EmailId'>Email address</label>
                                {errors.email && <p className='mt-2 text-white'>{errors.email.message}</p>}
                            </div>

                            <div className='form-floating mb-3'>
                                <img src={ArrowDownIcon} className='arrow-select' alt='ArrowDownIcon' />
                                <select className='form-select' {...register('auditionDetails')}>
                                    <option value=''>Select Preferred Audition Location</option>
                                    {auditionDetails.map((item) => {
                                        return (
                                            <option key={item.value} value={item.value} disabled={item.disabled}>
                                                {item.label}
                                            </option>
                                        );
                                    })}
                                </select>
                                <label>Preferred Audition Location</label>
                                {errors.auditionDetails && (
                                    <p className='mt-2 text-white'>{errors.auditionDetails.message}</p>
                                )}
                            </div>

                            <button
                                className={`w-100 btn-theme btn-secondary ${isLoading && 'disable-btn'}`}
                                type='submit'
                                disabled={isLoading}
                            >
                                Next
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepThree;
