import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeaderSubPages from '../../components/header/header-sub-pages';
import '../../assets/css/merchandise-style.css';
import products from '../../data/bpl-merchandise-v2.json';

const Merchandise = () => {
    useEffect(() => {
        document.title = 'Bhangra Premier League Bazaar - Bhangra Premier League - Red FM';
    }, []);
    return (
        <>
            <HeaderSubPages pageName='Bhangra Premier League Bazaar' />

            {/* <div className='d-flex align-items-center justify-content-center vh-76'>
                <h1 className='text-white'>Coming Soon</h1>
            </div> */}

            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-10'>
                            <div className='merchandise-main-box'>
                                <div className='row g-0'>
                                {products.map((product) => (
                                        <>
                                            <div className='col-lg-4 col-12'>
                                                <div className='merchandise-single-box'>
                                                    <div className='merchandise-img-rounded'>
                                                        <img src={product.image} className='img-fluid' alt='pf' />
                                                    </div>
                                                    <div className='content-bottom merchandise'>
                                                        <span className='category'>{product.category}</span>
                                                        {
                                                            (
                                                                () => {
                                                                if(product.gender !== ''){
                                                                    return(
                                                                        <>
                                                                            <span className='gender'>{product.gender}</span>
                                                                        </>
                                                                    )
                                                                }
                                                                }
                                                            )()
                                                        }
                                                        <h3 className='merchandise-name'>
                                                            {product.designName}
                                                        </h3>
                                                        <p>{product.description}</p>
                                                        <Link to={product.link} target="_blank" className="buy-now">Buy Now</Link>
                                                    </div>
                                                </div>
                                            </div>      
                                        </>
                                    )
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Merchandise;
