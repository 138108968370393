import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import * as yup from 'yup';

import UploadCloudIcon from '../../assets/icons/upload-cloud.svg';
import { useAuthContext } from '../../context/auth.context';
import service, { methods } from '../../service';

const supportFileFormat = [
    'video/mp4',
    'video/mov',
    'video/mpeg4',
    'video/avi',
    'video/wmv',
    'video/mpegps',
    'video/flv',
    'video/3gpp',
    'video/webm',
    'video/dnxhr',
    'video/prores',
    'video/cineform',
    'video/quicktime',
];

const schema = yup
    .object({
        file: yup
            .mixed()
            .test('required', 'Video is required', (value) => value.length === 1)
            .test(
                'fileType',
                'Unsupported video format',
                (value) => value.length && supportFileFormat.includes(value[0].type),
            ),
        description: yup.string(),
    })
    .required();

const StepFive = () => {
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const { register, handleSubmit, setValue, formState, setError } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });
    const { errors } = formState;

    const { handleNextStepParticipate } = useAuthContext();

    const onParticipantVideoUpload = async (data) => {
        setIsLoading(true);
        const payload = {
            description: data.description,
        };
        try {
            const res = await service({
                url: 'participant/submit/',
                data: payload,
                method: methods.POST,
            });
            if (res?.status === 1) {
                handleNextStepParticipate(6);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
            console.error(`Error :: onParticipantVideoUpload :: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemoveImage = () => {
        setVideoUrl('');
        setValue('file', undefined);
    };

    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];

        if (!selectedFile) {
            setError('file', {
                type: 'required',
                message: 'Video is required',
            });
            return;
        }
        if (!supportFileFormat.includes(selectedFile.type)) {
            toast.error(
                'Uploaded video is not in supported format. Please upload video in any of the following formats mp4, mov, mpeg4, avi, wmv, mpegps, flv, 3gpp, webm, dnxhr, prores, cineform,quicktime',
            );
            setError('file', {
                type: 'fileType',
                message: 'Unsupported video format',
            });
            return;
        }

        setFileUploadLoading(true);
        try {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('file_name', selectedFile.name);
            const res = await service({
                url: 'participant/upload/',
                method: methods.POST,
                data: formData,
            });
            setValue('file', e.target.files);
            setVideoUrl(`${process.env.REACT_APP_BASE_API_URL}/${res.payload.file_path}`);
        } catch (error) {
            setFileUploadLoading(false);
            toast.error(error);
            console.error(`Error :: handleFileChange :: ${error}`);
        } finally {
            setFileUploadLoading(false);
        }
    };

    return (
        <div className='content-box'>
            <div className='row justify-content-center align-items-start vh-100'>
                <div className='col-lg-12'>
                    <div className='content-top  mb-3 pt-5 pt-lg-0'>
                        <div className='steps-dash'>
                            <div className='step active'></div>
                            <div className={`step ${videoUrl && 'active'}`}></div>
                        </div>
                    </div>
                    <div className='login-form-box'>
                        <form onSubmit={handleSubmit(onParticipantVideoUpload)} className='d-block'>
                            <div className='mb-3'>
                                {fileUploadLoading && (
                                    <div className='text-center'>
                                        <Spinner animation='border' variant='warning' />
                                        <p className='text-white'>Please wait </p>
                                    </div>
                                )}
                                {videoUrl && !fileUploadLoading && (
                                    <div className='text-center'>
                                        <div className='big-video-container bg-white'>
                                            <ReactPlayer
                                                url={videoUrl}
                                                width='100%'
                                                height='100%'
                                                className='react-player'
                                                loading='lazy'
                                                playsinline
                                                controls
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            disablePictureInPicture: true,
                                                            controlsList: 'noplaybackrate nodownload',
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                        <button className='btn text-yellow' onClick={handleRemoveImage}>
                                            Remove
                                        </button>
                                    </div>
                                )}

                                {!videoUrl && !fileUploadLoading && (
                                    <>
                                        <div className='video-upload-btn'>
                                            <label className='input-group-text' htmlFor='inputGroupFile01'>
                                                <img src={UploadCloudIcon} alt='UploadCloudIcon' className='m-auto' />
                                                <span>Tap to Upload Video</span>
                                            </label>
                                            <input
                                                type='file'
                                                className='form-control'
                                                id='inputGroupFile01'
                                                onChange={handleFileChange}
                                                accept={supportFileFormat.join(',')}
                                            />
                                        </div>
                                        <p className='fs-14 text-white mb-1'>
                                            <span className='fw-bold'>Supported video:</span> mp4, mov, mpeg4, avi, wmv,
                                            mpegps, flv, 3gpp, webm, dnxhr, prores, cineform, quicktime
                                        </p>
                                        <p className='fs-14 text-white mb-1'>
                                            <span className='fw-bold'>Max File Size: </span> 100MB
                                        </p>
                                        {errors.file && <p className='mt-2 text-white fs-14'>{errors.file.message}</p>}
                                    </>
                                )}
                            </div>

                            <div className='form-floating'>
                                <textarea
                                    className='form-control'
                                    placeholder=''
                                    style={{ height: '80px' }}
                                    id='floatingTextarea2'
                                    {...register('description')}
                                ></textarea>
                                <label htmlFor='floatingTextarea2'>Comments</label>
                            </div>

                            <div className='back-next-btns'>
                                <div className='row mt-5'>
                                    <div className='col'>
                                        <button
                                            className='w-100 btn-theme btn-secondary bg-transparent text-yellow text-center'
                                            onClick={() => handleNextStepParticipate(4)}
                                        >
                                            Previous
                                        </button>
                                    </div>
                                    <div className='col'>
                                        <button
                                            className={`w-100 btn-theme btn-secondary text-center ${
                                                isLoading && 'disable-btn'
                                            }`}
                                            type='submit'
                                            disabled={isLoading}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepFive;
