import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';

import ArrowBack from '../../assets/icons/arrow-back.svg';
import SearchIcon from '../../assets/icons/search.svg';

const HeaderSubPages = ({ pageName, showSearch, search, handleChange }) => {
    return (
        <header className='py-4'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-3'>
                        <Link className='btn-transparent btn border-0 ps-0' to='/'>
                            <img src={ArrowBack} className='img-fluid' alt='ArrowBack' />
                        </Link>
                    </div>
                    <div className='col-6 text-center'>
                        <h2 className='page-title'>{pageName}</h2>
                    </div>
                </div>
                {showSearch && (
                    <Row className='mt-2'>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12} className='mx-auto'>
                            <div className='form-floating input-with-search-icon d-block'>
                                <input
                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    value={search}
                                    onChange={handleChange}
                                />
                                <img src={SearchIcon} className='img-fluid' alt='arrow-down' />

                                <label>Search</label>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </header>
    );
};

export default HeaderSubPages;
