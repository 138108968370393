import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import IcoCheckSuccess from '../../assets/icons/check-success.svg';
import { useAuthContext } from '../../context/auth.context';
import { useUserContext } from '../../context/user-context';
import service, { methods } from '../../service';
import { PARTICIPANT } from '../../constants';

const StepSix = () => {
    const { handleClose } = useAuthContext();
    const { setUser, setUserType } = useUserContext();

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const res = await service({
                    url: 'profile/participant/',
                    method: methods.GET,
                });
                if (res.status === 1) {
                    setUser(res.payload);
                    setUserType(PARTICIPANT);
                    handleClose();
                }
            } catch (error) {
                toast.error(error);
                console.error(`Error :: fetchUserDetails :: ${error}`);
            }
        };
        setTimeout(() => {
            fetchUserDetails();
        }, 3000);
    }, [handleClose, setUser, setUserType]);

    return (
        <div className='content-box bg-green'>
            <div className='row justify-content-center align-items-center vh-100'>
                <div className='col-lg-12'>
                    <div className='login-form-box text-light text-center'>
                        <img src={IcoCheckSuccess} alt='IcoCheckSuccess' className='m-auto' />
                        <h3 className='mt-3'>Thankyou!</h3>
                        <p>Your participation has been uploaded successfully</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepSix;
