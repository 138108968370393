import React from 'react';

import '../assets/css/loader-style.css';
import logo from '../assets/images/logo.png';

const Loader = () => {
    return (
        <div className='loader-container'>
            <div className='text-center m-auto'>
                <img src={logo} alt='logo' width='140px' />
                <div className='loader mt-3 mx-auto'> </div>
            </div>
        </div>
    );
};

export default Loader;
